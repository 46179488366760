import { useState, useEffect } from 'react'
import './GroupPage.css'

const GroupPage = (props) => {

    return(
        <div className="group-page">
            <ul>
                {/* {mappedPeople} */}
            </ul>
            <h3>group page</h3>
        </div>
    )
}

export default GroupPage