import styled from "styled-components";

export const HeaderContainer = styled.header`
    position: relative;
    margin: none;
    height: 60px;
    min-width: 300px;
    background: linear-gradient(0deg,#5077be,#6495ed);
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
`